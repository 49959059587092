import { Space, Avatar } from 'antd';
import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface BreadcrumbsProps {
  mobile?: boolean;
}

export default {
  Container: styled.div`
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 15;
    flex-shrink: 0;

    @media (min-width: ${BREAKPOINTS.XL}) {
      height: 64px;

      &::before {
        content: '';
        position: absolute;
        left: -8px;
        top: 0;
        width: 8px;
        height: 100%;
        border-top-left-radius: 8px;
        border-bottom: 1px solid #e1e1e1;
        background: #fff;
      }
    }
  `,

  RightButtons: styled(Space)`
    padding-left: 8px;
    margin-right: 24px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-right: 0;
    }
  `,

  Logo: styled.div`
    display: none;
    align-items: center;
    order: 4;

    & img {
      width: 135px;
      height: 28px;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
      order: 3;
    }
  `,

  Hamburger: styled.div`
    margin-right: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    order: 1;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
    }
  `,

  OrderContext: styled.button`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 200px;
    border: 0;
    border-radius: 8px 0px 0px 8px;
    margin: 0;
    padding: 12px 0;
    outline: none;
    text-align: left;
    background: #fff6c2;
    font-size: 18px;
    cursor: pointer;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: fixed;
      z-index: 2;

      left: 0;
      bottom: 0;
      width: 100%;
      height: 56px;
      border-radius: 0;
      padding: 0 0 0 24px;
      font-size: 12px;
    }
  `,

  OrderContextText: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
      flex-direction: column;

      & > div {
        margin: 0;
      }
    }
  `,

  CaretIcon: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    opacity: 0.4;
    transform: rotate(180deg);

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  MobileCaretIcon: styled.div`
    display: none;
    margin-left: auto;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
    }
  `,

  Br: styled.br`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  OrderDesc: styled.div`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  OrderDescMobile: styled.div`
    font-size: 12px;
    color: #5c5946;
    display: inline-block;
    margin-left: 8px;
    display: none;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: inline-block;
    }
  `,

  Button: styled.button``,

  Title: styled.div`
    position: absolute;
    left: 56px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Inter';
    font-size: 16px;
    line-height: 17.6px;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 230px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      left: 40px;
      right: 190px;
      font-size: 14px;
    }
  `,

  Breadcrumbs: styled.div<BreadcrumbsProps>`
    display: flex;
    align-items: center;
    font-size: 16px;
    position: absolute;
    left: 56px;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 230px;

    & > *:not(:first-child) {
      margin-left: 16px;
    }

    a {
      color: #000;

      &:hover {
        color: #000;
      }
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      left: 40px;
      right: auto;
    }

    ${({ mobile }) =>
      mobile &&
      css`
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        right: auto;
        height: 40px;
        background-color: #fff;
        border-bottom: 1px solid #e1e1e1;
        transform: none;
        flex-shrink: 0;
        white-space: initial;
        z-index: 5;

        @media (max-width: ${BREAKPOINTS.MD}) {
          left: auto;
        }
      `}
  `,

  BreadcrumbsIcon: styled.div`
    margin: 0 12px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  User: styled(Avatar)`
    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,
};
