import { actionTypes } from './actionTypes';
import { orderSources } from 'entities/order';

const loadPayment = (id: number | string) => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: orderSources.payment(id),
    },
  },
});

const loadPaymentProvider = (id: number | string) => ({
  type: actionTypes.LOAD_PROVIDER,
  payload: {
    request: {
      url: orderSources.provider(id),
    },
  },
});

const createPayment = (id: number | string, data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.payment(id),
      data,
    },
  },
});

const deletePayment = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.payment(id),
    },
  },
});

const deleteSuccessPayment = (id: number | string) => ({
  type: actionTypes.DELETE_SUCCESS_PAYMENT,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.paymentSuccess(id),
    },
  },
});

const successPayment = (id: number | string) => ({
  type: actionTypes.SUCCESS,
  payload: {
    request: {
      method: 'GET',
      url: orderSources.paymentSuccess(id),
    },
  },
});

const successTimePayment = (id: number | string, data: any) => ({
  type: actionTypes.SUCCESS_TIME,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.paymentSuccess(id),
      data,
    },
  },
});

const createInvoice = (id: number | string) => ({
  type: actionTypes.CREATE_INVOICE,
  payload: {
    request: {
      url: orderSources.createInvoice(id),
    },
    id,
  },
});

const sendInvoice = (id: number | string) => ({
  type: actionTypes.SEND_INVOICE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.sendInvoice(id),
    },
    id,
  },
});

const clearData = () => ({
  type: actionTypes.CLEAR_DATA,
});

export {
  loadPayment,
  loadPaymentProvider,
  createPayment,
  deletePayment,
  deleteSuccessPayment,
  successPayment,
  successTimePayment,
  createInvoice,
  sendInvoice,
  clearData,
};
