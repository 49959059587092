import config from 'config/config';

export const invoicesSources = {
  root: `${config.TICKETS_API_URL}/admin/v1/invoices_list`,
  create: `${config.TICKETS_API_URL}/admin/v1/invoices`,
  rejectReasonsList: `${config.TICKETS_API_URL}/admin/v1/invoices-reject-reasons`,
  count: `${config.TICKETS_API_URL}/admin/v1/invoices-count`,
  detail: (id: number | string) => `${config.TICKETS_API_URL}/admin/v1/invoices/${id}`,
  assign: (id: number | string) => `${config.TICKETS_API_URL}/admin/v1/invoices/${id}/assign`,
  setAwait: (id: number | string) => `${config.TICKETS_API_URL}/admin/v1/invoices/${id}/await`,
  toOrder: (id: number | string) => `${config.TICKETS_API_URL}/admin/v1/invoices/${id}/to-order`,
  comments: (id: number | string) => `${config.TICKETS_API_URL}/admin/v1/invoices/${id}/chat`,
  addCommentAttachment: (id: number | string) => `invoice/${id}/comment-attachment`,
  loadCommentAttachment: (id: number | string, attachmentId: number | string) =>
    `invoice/${id}/${attachmentId}/comment-proxy`,
  search: `${config.TICKETS_API_URL}/admin/v1/invoices-search`,
};
