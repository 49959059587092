import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import React from 'react';
import SplittedNumber from 'components/SplittedNumber';
import SEAT_SELECTOR_TYPES from '../constants/SEAT_SELECTOR_TYPES';
import { TICKET_HIGHLIGHT } from '../constants/TICKET_TYPES';
import { convertSeats } from './seats';

const getTotalPrice = (tickets: any): number => {
  const price = tickets.reduce((acc: number, ticket) => {
    const currPrice = Number(ticket.discount_price) || Number(ticket.price);
    return acc + Number(currPrice);
  }, 0);

  return price && price.toFixed(2);
};

const getPurchasePrice = (tickets) => {
  const price = tickets.reduce((acc: number, ticket) => {
    const currPrice = Number(ticket.purchase_price);
    return acc + Number(currPrice);
  }, 0);

  return `${price && price.toFixed(2)}`;
};

const orderTickets = (tickets) => orderBy(tickets, (ticket) => parseInt(ticket.seat, 10), ['asc']);

const selectSeat = (data, state) => {
  const { announcement, count, type, ticket, shouldClear } = data;
  const selectedAnnouncements = shouldClear ? [] : state;
  const currentAnnouncement = selectedAnnouncements.find((i) => i.id === announcement.id);

  switch (type) {
    case SEAT_SELECTOR_TYPES.PLAIN: {
      // add selected seat if array is empty
      if (!currentAnnouncement) {
        return [
          ...selectedAnnouncements,
          {
            ...announcement,
            ticketsType: type,
            selectedTickets: [ticket],
          },
        ];
      }

      // delete array key if no one seat is selected
      const ticketIsExist = currentAnnouncement.selectedTickets.find((i) => i.id === ticket.id);

      if (currentAnnouncement && ticketIsExist && currentAnnouncement.selectedTickets.length === 1) {
        return selectedAnnouncements.filter((i) => i.id !== announcement.id);
      }

      // add selected seat
      return selectedAnnouncements.map((announcementItem) => {
        if (announcementItem.id !== currentAnnouncement.id) return announcementItem;

        const isExist = announcementItem.selectedTickets.find(
          (selectedTicket) => selectedTicket.id === ticket.id,
        );
        const selectedTickets = isExist
          ? announcementItem.selectedTickets.filter((selectedTicket) => selectedTicket.id !== ticket.id)
          : [...announcementItem.selectedTickets, ticket];

        return {
          ...announcementItem,
          selectedTickets,
        };
      });
    }

    case SEAT_SELECTOR_TYPES.NUMBERED: {
      if (!currentAnnouncement && count) {
        return [
          ...selectedAnnouncements,
          {
            ...announcement,
            ticketsType: type,
            selectedTickets: count,
          },
        ];
      }

      if (currentAnnouncement && count === 0) {
        return selectedAnnouncements.filter((i) => i.id !== announcement.id);
      }

      return selectedAnnouncements.map((announcementItem) => {
        if (announcementItem.id === announcement.id) {
          return {
            ...announcementItem,
            selectedTickets: count,
          };
        }

        return announcementItem;
      });
    }

    default:
      return [];
  }
};

const getSelectedAnnouncementsSellers = (announcements) =>
  announcements.reduce((acc, announcement) => {
    acc[announcement.create_by.company.id] = acc[announcement.create_by.company.id] || {
      announcements: [],
    };

    acc[announcement.create_by.company.id].announcements.push({
      id: announcement.id,
      tickets:
        announcement.ticketsType === SEAT_SELECTOR_TYPES.PLAIN
          ? announcement.selectedTickets.map((seat) => seat.id)
          : announcement.selectedTickets,
    });
    return acc;
  }, {});

const groupTickets = (tickets, checkGlass?: boolean, checkFacePrice = false) =>
  tickets
    ? tickets.reduce(
        (acc, ticket) => {
          const row = ticket.row || 'count';
          const isStanding = ticket.seat ? 'not-standing' : 'is-standing';
          const price = Number(ticket.discount_price) || Number(ticket.price);
          const { extra } = ticket;
          const { face_price: facePrice, purchase_price: purchasePrice } = ticket;
          const rowName = `${row}_${isStanding}_${price}${
            checkFacePrice ? `_${facePrice}_${purchasePrice}` : ``
          }${checkGlass && !isEmpty(extra) ? '_extra' : ''}`;
          const ticketSector = ticket.sector || ticket.category;

          acc.groupedTickets[ticketSector] = acc.groupedTickets[ticketSector] || {
            [rowName]: [],
          };

          acc.groupedTickets[ticketSector][rowName] = acc.groupedTickets[ticketSector][rowName] || [];

          acc.groupedTickets[ticketSector][rowName].push({
            ...ticket,
            checkGlass: checkGlass && !isEmpty(extra),
          });

          acc.totalPrice += price;

          return acc;
        },
        {
          groupedTickets: {},
          totalPrice: 0,
        },
      )
    : {
        groupedTickets: {},
        totalPrice: 0,
      };

const renderTickets = (tickets: any) => {
  let isStanding;
  // @ts-ignore
  if (!head(tickets).seat) {
    isStanding = true;
  }

  const ticketsArray = map(tickets, (ticket) => ticket.seat);

  if (isStanding) {
    return (
      <SplittedNumber withSuffix suffix="шт">
        {ticketsArray.length}
      </SplittedNumber>
    );
  }

  return convertSeats(ticketsArray);
};

const groupTicketsBySectorAndRow = (tickets) => {
  const groupedSectors = omit(groupBy(tickets, 'sector'), 'null');
  const groupedCategories = omit(groupBy(tickets, 'category'), 'null');

  const mergedSectorsAndCategories = reduce(
    omit({ ...groupedSectors, ...groupedCategories }, 'undefined'),
    (acc, curr, index) => {
      acc[index] = groupBy(curr, 'row');

      return acc;
    },
    {},
  );

  return mergedSectorsAndCategories;
};

const groupTicketsBySectorRowAndPrices = (tickets) =>
  reduce(
    tickets,
    (acc, ticket) => {
      const row = ticket.row || 'count';
      const sector = ticket.sector || ticket.category;
      const isStanding = ticket.seat ? 'not-standing' : 'is-standing';
      const { price, purchase_price: purchasePrice, total } = ticket;

      const rowName = `${row}_${isStanding}_${price}_${purchasePrice}_${total}`;

      acc[sector] = acc[sector] || { [rowName]: [] };
      acc[sector][rowName] = acc[sector][rowName] || [];
      acc[sector][rowName].push(ticket);

      return acc;
    },
    {},
  );

const splitTicketsByFlag = (tickets, highlight) => {
  let groupedTickets = null;

  const firstTicket: any = head(tickets);
  const isFoundRequried = includes(highlight, TICKET_HIGHLIGHT.IS_FOUND);
  const isPreorderIsRequried = includes(highlight, TICKET_HIGHLIGHT.IS_PREORDER);
  const cancelledPurchaseRequried = includes(highlight, TICKET_HIGHLIGHT.CANCELLED_PURCHASE);
  const IsSoldRequried = includes(highlight, TICKET_HIGHLIGHT.IS_SOLD);
  const IsNotSoldrequried = includes(highlight, TICKET_HIGHLIGHT.IS_NOT_SOLD);

  ({ groupedTickets } = tickets.reduce(
    (acc, ticket) => {
      const ticketIsFound = Boolean(get(ticket, 'extra.is_found'));
      const ticketIsPreorder = Boolean(ticket.is_preorder);
      const ticketCancelledPurchase = Boolean(get(ticket, 'extra.cancelled_purchase'));
      const ticketIsSold = Boolean(ticket.is_sold);
      const ticketIsNotSold = Boolean(!ticket.is_sold);

      const isFoundIsDiff = ticketIsFound !== acc.currentIsFound;
      const isPreorderIsDiff = ticketIsPreorder !== acc.currentIsPreorder;
      const cancelledPurchaseIsDiff = ticketCancelledPurchase !== acc.currentCancelledPurchase;
      const isSoldIsDiff = ticketIsSold !== acc.currentIsSold;
      const isNotSoldIsDiff = ticketIsNotSold !== acc.currentIsNotSold;

      if (
        (isFoundRequried && isFoundIsDiff) ||
        (isPreorderIsRequried && isPreorderIsDiff) ||
        (cancelledPurchaseRequried && cancelledPurchaseIsDiff) ||
        (IsSoldRequried && isSoldIsDiff) ||
        (IsNotSoldrequried && isNotSoldIsDiff)
      ) {
        acc.index += 1;
        acc.currentIsFound = ticketIsFound;
        acc.currentIsPreorder = ticketIsPreorder;
        acc.currentCancelledPurchase = ticketCancelledPurchase;
        acc.currentIsSold = ticketIsSold;
        acc.currentIsNotSold = ticketIsNotSold;
      }

      acc.groupedTickets[acc.index] = acc.groupedTickets[acc.index] || [];
      acc.groupedTickets[acc.index].push(ticket);

      return acc;
    },
    {
      index: 0,
      groupedTickets: [],
      currentIsFound: Boolean(get(firstTicket, 'extra.is_found')),
      currentIsPreorder: Boolean(firstTicket.is_preorder),
      currentCancelledPurchase: Boolean(get(firstTicket, 'extra.cancelled_purchase')),
      currentIsSold: Boolean(firstTicket.is_sold),
      currentIsNotSold: Boolean(!firstTicket.is_sold),
    },
  ));

  groupedTickets = orderBy(groupedTickets, (group) => parseInt(group[0].seat, 10), ['asc']);

  return groupedTickets;
};

const groupTicketsByFlag = (groupedTickets, ticketsForHighlight) => {
  const ignoredHighligts = [TICKET_HIGHLIGHT.AVAILABLE_FOR_PURCHASE, TICKET_HIGHLIGHT.AVAILABLE_FOR_SALE];
  const highlight = ticketsForHighlight
    ? ticketsForHighlight.filter((item) => !includes(ignoredHighligts, item))
    : [];

  const tickets = reduce(
    groupedTickets,
    (acc, curr, index) => {
      acc[index] = reduce(
        curr,
        (innerAcc, innerCurr, innerIndex) => {
          // eslint-disable-next-line no-param-reassign
          innerAcc[innerIndex] = splitTicketsByFlag(innerCurr, highlight);

          return innerAcc;
        },
        {},
      );

      return acc;
    },
    {},
  );

  return tickets;
};

export {
  getTotalPrice,
  orderTickets,
  selectSeat,
  getSelectedAnnouncementsSellers,
  groupTickets,
  renderTickets,
  groupTicketsBySectorAndRow,
  groupTicketsByFlag,
  getPurchasePrice,
  splitTicketsByFlag,
  groupTicketsBySectorRowAndPrices,
};
