import { NotificationManager } from 'react-notifications';
import JsFileDownload from 'js-file-download';
import head from 'lodash/head';
import { DESTINATION_TYPES, PRICE_CHANGE_TYPES } from 'shared/constants/ORDER';
import api from 'shared/services/api';
import { orderSources } from 'entities/order';

function getDiscountDestination(price, discountPrice) {
  if (!discountPrice) return null;

  const priceDiff = price - discountPrice;
  return priceDiff >= 0 ? DESTINATION_TYPES.DISCOUNT : DESTINATION_TYPES.MARKUP;
}

function getDiscountLabel(price, discountPrice) {
  const priceDiff = price - discountPrice;
  return priceDiff >= 0 ? 'Скидка' : 'Наценка';
}

function updateOrder(data, { id, field, value }) {
  return data.map((order) => {
    if (order.id === id) {
      return {
        ...order,
        [field]: value,
      };
    }

    return order;
  });
}

interface CalcNewPriceProps {
  price: number;
  discountType: number;
  amount: number;
  destination: any;
}

function calcNewPrice({ price, discountType, amount, destination }: CalcNewPriceProps) {
  const sign = Number(destination) === DESTINATION_TYPES.DISCOUNT ? -1 : 1;

  if (!amount || amount < 0) return price;

  if (discountType === PRICE_CHANGE_TYPES.NUMBER) {
    return price + amount * sign;
  }

  if (discountType === PRICE_CHANGE_TYPES.PERCENTAGE) {
    return price + price * (amount / 100) * sign;
  }

  return price.toFixed(2);
}

const getMaxPrice = ({ values, destination, price }) => {
  if (
    destination === DESTINATION_TYPES.DISCOUNT &&
    Number(values.discount_type) === PRICE_CHANGE_TYPES.NUMBER
  ) {
    return price;
  }

  if (
    destination === DESTINATION_TYPES.MARKUP &&
    Number(values.discount_type) === PRICE_CHANGE_TYPES.PERCENTAGE
  ) {
    let maxValue = ((999999999 - price) / price) * 100;
    maxValue = Number(maxValue.toFixed(2));

    return maxValue;
  }

  if (
    destination === DESTINATION_TYPES.DISCOUNT &&
    Number(values.discount_type) === PRICE_CHANGE_TYPES.PERCENTAGE
  ) {
    return 99;
  }

  return 1000000000 - price;
};

const downloadOrderBlank = ({ id }) => {
  api
    .get(orderSources.downloadReport(id), {
      responseType: 'blob',
    })
    .then((response) => JsFileDownload(response.data, `order-${id}-blank.pdf`))
    .catch((error) => {
      if (error.response.data) {
        const fr = new FileReader();

        fr.onload = function onLoad() {
          // @ts-ignore
          const { error: parsedError } = JSON.parse(this.result);

          NotificationManager.error(head(parsedError), 'Ошибка');
        };

        fr.readAsText(error.response.data);
      } else {
        NotificationManager.error('Ошибка');
      }
    });
};

const checkAccessToChangeOrder = ({ account, seller }) => seller && account.company.id === seller.id;

export {
  getDiscountDestination,
  getDiscountLabel,
  updateOrder,
  calcNewPrice,
  getMaxPrice,
  downloadOrderBlank,
  checkAccessToChangeOrder,
};
